import React, { useEffect, useRef, useState } from 'react';
import { CountUp } from 'countup.js';
import { useInView } from 'react-intersection-observer';

export default function Footer() {
    const [isVisible, setIsVisible] = useState(false);
    const { ref, inView } = useInView({
      threshold: 0.5,
    });

    const countUpRef = useRef(null);

    useEffect(() => {
        const lastUpdateDate = localStorage.getItem('d');
        const currentDate = new Date().toISOString().slice(0, 10);
        let storedValue = parseFloat(localStorage.getItem('v')) || 0;
    
        if (lastUpdateDate !== currentDate) {
          storedValue = Math.floor(Math.random() * (999999 - 910000 + 1)) + 910000; 
          localStorage.setItem('v', storedValue);
          localStorage.setItem('d', currentDate);
        }
    
        const countUp = new CountUp(countUpRef.current, storedValue, {
          duration: 2,
          startVal: 0,
        });

        if (inView) {
            if (!countUp.error) {
              countUp.start();
            } else {
              console.error(countUp.error);
            }
        }
    }, [inView]);

    return (
        <footer className="bg-black text-white footer py-4" ref={ref}>
            <div className="container d-flex flex-column">
                <div className='d-flex justify-content-center'style={{color: "#bbbbbb"}}>
                    <span ref={countUpRef} className='fs-1 align-self-center'></span>
                </div>
                <p className="text-center mt-4">Copyright © 2024 by Wager168 娛樂評價網. All Rights Reserved.</p>
            </div>
        </footer>
    )
}